<template>
  <FormCard v-if="role" :config="general_data" @DataSaved="UpdateData" />
  <div v-else class="loading">
    <CSpinner color="secondary" size="lg" />
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import FormCard from "../../../common/form/Card";

export default {
  model: {
    prop: "role",
    event: "change",
  },
  props: ["role", "editmode"],
  components: {
    FormCard,
  },
  // watch: {
  //   "pp.category__macrocategory_id": function (nV, oV) {
  //     console.log("changed macrocategory", nV, oV);
  //     // this.UpdateMacrocategoryOptions();
  //     this.pp.category__parent_id = null;
  //     // this.UpdateLtypeOptions();
  //     this.pp.ptype_id = null;
  //   },
  //   "pp.category__parent_id": function (nV, oV) {
  //     console.log("changedparent", nV, oV);
  //     // this.UpdateCategoryOptions();
  //     this.pp.category_id = null;
  //   },
  // },
  methods: {
    UpdateData(data) {
      // console.log("UpdateData")
      // this.role = data
      this.$emit("change", data);
    },
  },
  computed: {
    general_data() {
      return {
        // title: "Informazioni pubbliche",
        data: this.role,
        active: this.editmode,
        fields: {
          image: {
            // title: "Logo rettangolare",
            description: this.$t("models.role_image.image_info"),
            type: "Cropper",
            size: 12,
            subsize: [12, 12],
            // ratio: 300 / 78,
            image: "image",
            image_cropping: "image_cropping",
            image_cropped: "image_cropped",
            circle: false,
          },
          name: {
            title: this.$t("models.role_image.name"),
            description: this.$t("models.role_image.name_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
            hide: true,
          },
          info: {
            title: this.$t("models.role_image.info"),
            type: "CKInput",
            size: 12,
            subsize: [3, 9],
            description: this.$t("models.role_image.info_info"),
            hide: true,
          },
          gallery: {
            label: this.$t("models.role_image.gallery"),
            type: "CInputCheckboxB",
            size: 6,
            subsize: [0, 12],
          },
          slideshow: {
            label: this.$t("models.role_image.slideshow"),
            type: "CInputCheckboxB",
            size: 6,
            subsize: [0, 12],
          },
        },
        dm: GetDataManager("auth_role_image", [this.$store.state.role.id]),
      };
    },
  },
};
</script>
