<template>
  <div>
    <br />
    <CRow>
      <CButtonToolbar aria-label="Toolbar with button groups" class="col-md-12">
        <CButtonGroup class="mx-1">
          <div>
            <vue-ladda
              :loading="image_loading"
              data-style="zoom-in"
              button-class="btn btn-secondary btn-sm"
              @click.prevent="$refs.file.click()"
              >{{ $t("role.profile.tab_images.load_image") }}</vue-ladda
            >
            <input
              type="file"
              ref="file"
              @change="uploadImage($event)"
              accept="image/*"
              class="d-none"
            />
          </div>
        </CButtonGroup>
        <CButtonGroup class="mx-auto">
          <CInput
            horizontal
            v-model="search"
            :placeholder="$t('role.profile.tab_images.search_placeholder')"
          >
            <template #append-content
              ><CIcon name="cil-magnifying-glass" />
            </template>
          </CInput>
        </CButtonGroup>
        <CButtonGroup class="mx-1">
          <CDropdown right :togglerText="ActiveTogglerLabel" color="secondary">
            <CDropdownItem @click="active = true">{{
              $t("role.profile.tab_images.filter_type.gallery")
            }}</CDropdownItem>
            <CDropdownItem @click="active = false">{{
              $t("role.profile.tab_images.filter_type.slideshow")
            }}</CDropdownItem>
            <CDropdownDivider />
            <CDropdownItem @click="active = null">{{
              $t("role.profile.tab_images.filter_type.all")
            }}</CDropdownItem>
          </CDropdown>
        </CButtonGroup>
      </CButtonToolbar>
    </CRow>
    <CRow v-if="images !== null">
      <!-- <CCol md="4">
        <ProfileImagesImage
          v-model="image_new"
          :editmode="true"
          ref="img_new"
        />
      </CCol> -->
      <CCol md="4" v-for="(image, index) in images" :key="index">
        <ProfileImagesImage v-model="images[index]" />
      </CCol>
    </CRow>
    <div v-else class="loading">
      <CSpinner color="secondary" size="lg" />
    </div>
    <CRow v-if="numPages > 1">
      <CCol md="12">
        <CPagination
          :active-page.sync="currentPage"
          :pages="numPages"
          responsive
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { GetDataManager, ApiCall } from "../../../ds/index";
import { Query, Predicate } from "@syncfusion/ej2-data";
import ProfileImagesImage from "./ProfileImagesImage";
// import ProductsListNew from "./ProductsListNew";

export default {
  data() {
    return {
      currentPage: 1,
      numPages: 1,
      images: null,
      search: "",
      active: null,
      awaitingSearch: false,

      cur_lang: this.$i18n.locale,
      image_new: null,
      image_loading: false,
    };
  },
  components: {
    ProfileImagesImage,
    // ProductsListNew,
  },
  watch: {
    currentPage: function () {
      this.UpdateImages();
    },
    active: function () {
      this.UpdateImages();
    },
    search: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.UpdateImages();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  computed: {
    ActiveTogglerLabel() {
      if (this.active == true) {
        return (
          this.$t("role.profile.tab_images.filter_type.title") +
          ": " +
          this.$t("role.profile.tab_images.filter_type.gallery")
        );
      } else if (this.active == false) {
        return (
          this.$t("role.profile.tab_images.filter_type.title") +
          ": " +
          this.$t("role.profile.tab_images.filter_type.slideshow")
        );
      }
      return (
        this.$t("role.profile.tab_images.filter_type.title") +
        ": " +
        this.$t("role.profile.tab_images.filter_type.all")
      );
    },
  },
  methods: {
    uploadImage(event) {
      this.image_loading = true;
      // console.log("load image");
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image_new = e.target.result;
          // this.image_cropping = "";
          let params = {
            image: this.image_new,
          };
          GetDataManager("auth_role_image", [this.$store.state.role.id])
            .insert(params)
            .then(
              () => {
                this.UpdateImages();
                this.$store.dispatch("toaster/add", {
                  title: this.$t("role.profile.tab_images.toast.add.title"),
                  text: this.$t("role.profile.tab_images.toast.add.text"),
                  autohide: true,
                });
                this.image_loading = false;
              },
              (reason) => {
                this.image_loading = false;
                let errors = JSON.parse(reason[0].error.response);
                this.dataForm.errors = errors;
                this.dataForm.loading = false;
              }
            );
        };
      }
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(input.files[0]);
      // this.dataForm.loading = true;
      // this.dataForm.errors = {};

      // let params = this.dataForm.formData;
      // Object.keys(params).forEach(function (key) {
      //   index(params, key, params[key]);
      // });
      // params.productproducer_id= this.pp.id
      //   'translations.it.name'.split('.').reduce((o,i)=>o[i], params)
    },
    UpdateImages() {
      let self = this;
      self.images = null;
      let predicate = new Predicate("role_id", "greaterthanorequal", 0);

      if (this.active == true) {
        predicate = predicate.and("gallery", "equal", true);
      } else if (this.active == false) {
        predicate = predicate.and("slideshow", "equal", true);
      }
      let query = new Query().where(predicate);
      if (!this.search == "") {
        query = query.search(this.search, ["name", "info"]);
      }
      query = query.page(this.currentPage, 12);
      // Update lots info
      ApiCall(
        GetDataManager("auth_role_image", [this.$store.state.role.id]),
        query,
        function (e) {
          self.numPages = Math.ceil(e.actual.Count / 12);
          self.images = e.result;
        }
      );
    },
  },
  created() {
    this.UpdateImages();
  },
};
</script>
